import { Helmet, Interfaces } from "@life-without-barriers/react-components"
import { Site } from "@life-without-barriers/gatsby-common"
import { graphql } from "gatsby"
import React from "react"

import { ContactUsLocation, LocationPageParameters } from "../../contentTypes"
import LocationResults from "../../components/contact-us/LocationResults"

interface Props {
  location: { pathname: string }
  data: {
    site: Site
    currentLocation: ContactUsLocation
    nearbyLocations: {
      edges: Array<{
        node: ContactUsLocation
      }>
    }
  }
  pageContext: {
    id: string
    nearbyIds: string[]
  }
  theme: Interfaces.Theme
}

const LocationTemplate = ({
  location,
  data: {
    site: { siteMetadata },
    currentLocation,
    nearbyLocations
  },
  pageContext: { nearbyIds }
}: Props) => {
  // The graphql query returns these in natural order where we need them in distance order.
  // The ids that get sent into the page are in distance order already - so map them in that order.
  const locationNodes = nearbyLocations.edges.map((e) => e.node)
  const orderedNearby: ContactUsLocation[] = nearbyIds.map(
    (id) => locationNodes.filter((l) => l.id === id)[0]
  )
  const lat = currentLocation.location.lat.toFixed(8)
  const lon = currentLocation.location.lon.toFixed(8)
  const searchParams: LocationPageParameters = {
    ...currentLocation.location,
    address: `${currentLocation.town} ${currentLocation.state} ${currentLocation.postcode}`,
    enteredAddress: `${currentLocation.town} ${currentLocation.state} ${currentLocation.postcode}`
  }
  return (
    <div>
      <Helmet
        title={`Contact us - near ${currentLocation.town} ${currentLocation.state} | ${siteMetadata.title}`}
        description="Contact Life Without Barriers for assistance with disability services, foster care, aged care and other programs."
        siteUrl={siteMetadata.siteUrl}
        path={location.pathname}
        geoPosition={`${lat};${lon}`}
      />
      <LocationResults
        searchParams={searchParams}
        orderedNearby={orderedNearby}
        currentLocation={currentLocation}
      />
    </div>
  )
}

export const query = graphql`
  query ($id: String!, $nearbyIds: [String!]!) {
    currentLocation: contentfulContactUsLocation(id: { eq: $id }) {
      ...contactUsLocationFragment
    }
    nearbyLocations: allContentfulContactUsLocation(
      filter: { id: { in: $nearbyIds } }
    ) {
      edges {
        node {
          ...contactUsLocationFragment
        }
      }
    }
    site {
      siteMetadata {
        title
        siteUrl
      }
    }
  }
`

export default LocationTemplate
